import {
  Conservative,
  ModeratelyAggressive,
  ModeratelyConservative,
  Aggressive,
  USEquityOnly,
  InvestingReason_Retirement,
  InvestingReason_Wealth,
  InvestingReason_Education,
  InvestingReason_Downpayment
} from '../../constants';

export const riskPreference = [
  { key: Conservative, value: 'Prefers to avoid risk' },
  { key: ModeratelyConservative, value: 'Cautious' },
    {
        key: ModeratelyAggressive,
        value: 'Willing to take controlled risks after adequate research',
    },
  { key: Aggressive, value: 'Willing to gamble if the payoff is great enough' },
    {
        key: USEquityOnly,
        displayValue: 'N/A - I am only looking for exposure to US Equities',
        value: 'I am only looking for exposure to US Equities',
        isInitialHidden: true
    },
];

export default function riskModelResolver(period: number, investFor: string, riskTolerance: string): string {
  let _period = period !== undefined ? period : 5;
  let _riskModel = "";

  if (_period >= 15 && (investFor === InvestingReason_Wealth || investFor === InvestingReason_Retirement)) {
    // console.log("risk model case 1");
    switch (riskTolerance) {
      case Conservative:
        _riskModel = ModeratelyConservative;
        break;
      case ModeratelyConservative:
        _riskModel = ModeratelyAggressive;
        break;
      case ModeratelyAggressive:
      case Aggressive:
        _riskModel = Aggressive;
        break;
      default:
        _riskModel = "";
    }
  }
  else if ((_period >= 15 && (investFor === InvestingReason_Downpayment || investFor === InvestingReason_Education)) ||
    ((_period >= 10 && _period < 15) && (investFor === InvestingReason_Wealth || investFor === InvestingReason_Retirement)) ||
    (_period < 10 && investFor === InvestingReason_Retirement)) {
    // console.log("risk model case 2");
    switch (riskTolerance) {
      case Conservative:
        _riskModel = ModeratelyConservative;
        break;
      case ModeratelyConservative:
      case ModeratelyAggressive:
        _riskModel = ModeratelyAggressive;
        break;
      case Aggressive:
        _riskModel = Aggressive;
        break;
      default:
        _riskModel = "";
    }
  }
  else if ((_period >= 10 && _period < 15) && investFor === InvestingReason_Education) {
    // console.log("risk model case 3");
    switch (riskTolerance) {
      case Conservative:
      case ModeratelyConservative:
        _riskModel = ModeratelyConservative;
        break;
      case ModeratelyAggressive:
      case Aggressive:
        _riskModel = ModeratelyAggressive;
        break;
      default:
        _riskModel = "";
    }
  }
  else if ((_period < 10 && investFor === InvestingReason_Wealth) ||
    ((_period >= 10 && _period < 15) && investFor === InvestingReason_Downpayment)) {
    // console.log("risk model case 4");
    switch (riskTolerance) {
      case Conservative:
        _riskModel = Conservative;
        break;
      case ModeratelyConservative:
        _riskModel = ModeratelyConservative;
        break;
      case ModeratelyAggressive:
      case Aggressive:
        _riskModel = ModeratelyAggressive;
        break;
      default:
        _riskModel = "";
    }
  }
  else if (_period < 10 && investFor === InvestingReason_Education) {
    // console.log("risk model case 5");
    switch (riskTolerance) {
      case Conservative:
        _riskModel = Conservative;
        break;
      case ModeratelyConservative:
      case ModeratelyAggressive:
        _riskModel = ModeratelyConservative;
        break;
      case Aggressive:
        _riskModel = ModeratelyAggressive;
        break;
      default:
        _riskModel = "";
    }
  }
  else if (_period < 10 && investFor === InvestingReason_Downpayment) {
    // console.log("risk model case 6");
    switch (riskTolerance) {
      case Conservative:
        _riskModel = Conservative;
        break;
      case ModeratelyConservative:
      case ModeratelyAggressive:
      case Aggressive:
        _riskModel = ModeratelyConservative;
        break;
      default:
        _riskModel = "";
    }
  }
  else {
    console.log("risk model other");
    switch (riskTolerance) {
      case Conservative:
        _riskModel = Conservative;
        break;
      case ModeratelyConservative:
        _riskModel = ModeratelyConservative;
        break;
      case ModeratelyAggressive:
        _riskModel = ModeratelyAggressive;
        break;
      case Aggressive:
        _riskModel = Aggressive;
        break;
      default:
        _riskModel = "";
    }
  }
  // console.log("resolved risk model="+_riskModel)
  return _riskModel;
}