import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import createMixins from '@material-ui/core/styles/createMixins';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

declare module '@material-ui/core/styles/createMixins' {
    interface Mixins {
        mainYellow: CSSProperties;
        mainWhite: CSSProperties;
        mainBlack: CSSProperties;
        mainFontFamily: CSSProperties;
        hoverColor: CSSProperties;
        mainBlue: CSSProperties;
        disabledColors: CSSProperties;
        borderColor: CSSProperties;
        divider: CSSProperties;
        greyColor: CSSProperties;
        plansBackgroundColor: CSSProperties;
        veryLightBlue: CSSProperties;
        headerHeight: string;
        progressBarHeight: string;
    }
}

const { breakpoints, spacing } = createMuiTheme();

export const mixins = createMixins(breakpoints, spacing, {
    mainYellow: {
        color: '#FFCE00'
    },
    mainWhite: {
        color: '#ffffff'
    },
    mainBlack: {
        color: '#000'
    },
    mainFontFamily: {
        fontFamily: 'Barlow',
    },
    hoverColor: {
        color: '#EAB003'
    },
    mainBlue: {
        color: '#123ab9'
    },
    disabledColors: {
        color: '#888888',
        backgroundColor: '#cccccc!important'
    },
    borderColor: {
        borderColor: '#383ec0',
    },
    divider: {
        backgroundColor: '#EEEEEE',
    },
    greyColor: {
        color: '#d9d9d9',
    },
    plansBackgroundColor: {
        backgroundColor: '#f6faff',
    },
    veryLightBlue: {
        color: '#e3f0fd',
    },
    headerHeight: '57.2px',
    progressBarHeight: '8px',
});

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: `${mixins.mainYellow.color}`,
        },
        secondary: {
            main: `${mixins.mainBlue.color}`,
        }
    },
    overrides: {
        MuiFormLabel: {
            root: {
                fontSize: '19px',
                fontFamily: `${mixins.mainFontFamily.fontFamily}`,
                '&$focused': { fontWeight: 'bold' }
            }
        },
        MuiInputBase: {
            input: {
                height: '50px',
                paddingBottom: '0!important',
                paddingTop: '0!important',
                backgroundColor: `${mixins.mainWhite.color}`,
                fontSize: '19px',
            },
        },
        MuiInputLabel: {
            outlined: {
                fontSize: '19px',
                fontWeight: 'normal',
                transform: 'translate(14px, 15px) scale(1)',
                '&$focused': { fontWeight: 'bold' }
            },
            root: {
                '&$focused': { fontWeight: 'bold' }
            }
        },
        MuiOutlinedInput: {
            input: {
                borderRadius: '5px',
            },
            root: {
                height: '50px',
                '&$focused $notchedOutline': {
                    borderColor: mixins.borderColor.borderColor,
                },
            },
            notchedOutline: {}
        },
        MuiSelect: {
            selectMenu: {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            }
        },
        MuiFormControl: {
            root: {
                marginBottom: '20px',
            }
        },
        MuiTypography: {
            body1: {
                fontFamily: `${mixins.mainFontFamily.fontFamily}`,
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiDivider: {
            root: {
                ...mixins.divider
            }
        },
        MuiButton: {
            root: {
                maxWidth: '345px',
                width: '302px',
                backgroundColor: `${mixins.mainYellow.color}`,
                fontSize: '16px',
                minHeight: '50px',
                lineHeight: '1.56',
                letterSpacing: '0.16px',
                height: 'auto',
                textTransform: 'capitalize',
                fontFamily: `${mixins.mainFontFamily.fontFamily}`,
                fontWeight: 'bold',
                '&$disabled': {
                    ...mixins.disabledColors
                },
                '&:hover': {
                    color: mixins.mainWhite.color,
                    backgroundColor: mixins.hoverColor.color
                },
                '@media (hover: none)': {
                    '&:hover': {
                        color: `${mixins.mainWhite.color}!important`,
                        backgroundColor: `${mixins.hoverColor.color}!important`
                    }
                }
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: 0,
            }
        },
        MuiRadio: {
            root: {
                marginRight: '10px',
                '&$checked': {
                    color: `${mixins.mainBlue.color}`,
                },
            }
        },
        MuiCheckbox: {
            root: {
                color: `${mixins.mainBlue.color}`,
                '&$checked': {
                    color: `${mixins.mainBlue.color}`,
                }
            }
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: 18,
            },
            label: {
                fontFamily: `${mixins.mainFontFamily.fontFamily}`,
                fontSize: '18px',
                fontWeight: 'bold',
                lineHeight: 1.39,
                letterSpacing: 0.01,
            }
        },
        MuiMobileStepper: {
            root: {
                backgroundColor: mixins.plansBackgroundColor.backgroundColor,
            },
            dotActive: {
                backgroundColor: mixins.mainBlue.color,
            }
        },
        MuiDialog: {
            paper: {
                borderRadius: '5px',
            }
        },
        MuiSwitch: {
            switchBase: {
                color: '#bdbdbd',
                '&$checked': {
                    color: `${mixins.mainBlue.color}!important`,
                },
            },
            track: {
                backgroundColor: '#ececec',
            },
        },
    },
});
