interface FirebaseFunction {
    name: string,
    method: HttpMethod,
    call: (body: object) => Promise<Response>
}

enum HttpMethod {
    POST = 'POST',
    GET = 'GET'
}

const FirebasePath = {
    development: 'http://localhost:5001/ri-beta/us-central1/',
    test: 'http://localhost:5001/ri-beta/us-central1/',
    production: 'https://us-central1-ri-beta.cloudfunctions.net/'
}

export class SubscribeToMailList implements FirebaseFunction {
    name = 'subscribeToMailList'
    method = HttpMethod.POST
    
    call(body: object): Promise<Response> {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const url = `${FirebasePath[process.env.NODE_ENV]}${this.name}`

        return fetch(url, { method: this.method, body: JSON.stringify(body) })
    }
}

export class PushAppState implements FirebaseFunction {
    name = 'pushAppState'
    method = HttpMethod.POST
    
    call(body: object): Promise<Response> {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const url = `${FirebasePath[process.env.NODE_ENV]}${this.name}`

        return fetch(url, { method: this.method, body: JSON.stringify(body) })
    }
}
