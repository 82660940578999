import { createStore } from 'redux';
import appReducer from './reducers';
import { AppStore } from './types';
import * as constants from '../constants';
import { START } from '../constants/routes';

export const initialState: AppStore = {
    investFor: '',
    riskTolerance: '',
    initialDeposit: 0,
    recurringAmount: -1,
    period: 0,
    portfolioModel: constants.TaxableAggressive,
    recModel: '',
    riskModel: '',
    initialInvestment: 0,
    lastCompletedSteps: [START],
    impactAreas: [],
    userFullName: '',
    userEmail: '',
    isUserOptIn: false,
    birthYear: '',
    howMutchShouldInvestInFirstYear: '',
    overallInvestedPortfolio: '',
    joinMailingList: false,
    joinEarlyAccess: false,
    joinedToMailChimp: false
};

export default function configureStore(initial: AppStore) {
  const store = createStore(appReducer, initial || initialState)
  
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default
      store.replaceReducer(nextReducer)
    })
  }

  return store
}
