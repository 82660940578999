export const SET_START = 'SET_START';
export const SET_INVESTMENT_GOALS = 'SET_INVESTMENT_GOALS';
export const SET_RISK_TOLERANCE = 'SET_RISK_TOLERANCE';
export const SET_IMPACT_AREAS = 'SET_IMPACT_AREAS';
export const SET_KNOW_YOUR_IMPACT = 'SET_KNOW_YOUR_IMPACT';
export const SET_RESULTS = 'SET_RESULTS';
export const GO_BACK = 'GO_BACK';
export const COMPLETE_STEP = 'COMPLETE_STEP';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_INFO = 'SET_PROFILE_INFO';
export const SET_PROFILE_COMPLETE = 'SET_PROFILE_COMPLETE';

export type SET_START = typeof SET_START;
export type SET_INVESTMENT_GOALS = typeof SET_INVESTMENT_GOALS;
export type SET_RISK_TOLERANCE = typeof SET_RISK_TOLERANCE;
export type SET_IMPACT_AREAS = typeof SET_IMPACT_AREAS;
export type SET_KNOW_YOUR_IMPACT = typeof SET_KNOW_YOUR_IMPACT;
export type SET_RESULTS = typeof SET_RESULTS;
export type GO_BACK = typeof GO_BACK;
export type COMPLETE_STEP = typeof COMPLETE_STEP;
export type SET_PROFILE = typeof SET_PROFILE;
export type SET_PROFILE_INFO = typeof SET_PROFILE_INFO;
export type SET_PROFILE_COMPLETE = typeof SET_PROFILE_COMPLETE;
