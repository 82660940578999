import { get } from 'lodash'
import { AppStore, AppActions } from "./types";
import {
  SET_IMPACT_AREAS,
  SET_START,
  SET_INVESTMENT_GOALS,
  SET_RISK_TOLERANCE,
  SET_RESULTS,
  GO_BACK,
  COMPLETE_STEP,
  SET_PROFILE,
  SET_PROFILE_INFO,
  SET_PROFILE_COMPLETE
} from "../constants/redux";
import {
  START,
  IMPACT_AREAS,
  INVEST_GOALS,
  RISK_TOLERANCE
} from "../constants/routes";
import { initialState } from ".";
import riskModelResolver from "../components/RiskTolerance/riskPreference";
import { pushAppState, subscribeToMailList } from "../firebase";

const checkCompletedSteps = (state: AppStore, step: string) => {
  return state.lastCompletedSteps.indexOf(step) === -1
    ? [...state.lastCompletedSteps, step]
    : [...state.lastCompletedSteps];
};

export default function appReducer(
  state: AppStore = initialState,
  action: AppActions
): AppStore {
  switch (action.type) {
    case SET_START:
      return {
        ...state,
        investFor: action.investFor,
        lastCompletedSteps: checkCompletedSteps(state, START),
      };
    case SET_INVESTMENT_GOALS:
      return {
        ...state,
        initialInvestment: action.initialInvestment,
        period: action.period,
        lastCompletedSteps: checkCompletedSteps(state, INVEST_GOALS),
      };
    case SET_RISK_TOLERANCE:
      const resolvedModel = riskModelResolver(state.period, state.investFor, action.riskTolerance);
      return {
        ...state,
        riskTolerance: action.riskTolerance,
        recModel: resolvedModel,
        riskModel: resolvedModel,
        lastCompletedSteps: checkCompletedSteps(state, RISK_TOLERANCE),
      };
    case SET_IMPACT_AREAS:
      return {
        ...state,
        impactAreas: action.impactAreas,
        lastCompletedSteps: checkCompletedSteps(state, IMPACT_AREAS),
      };
    case SET_PROFILE: 
      return {
        ...state,
        userFullName: action.userFullName,
        userEmail: get(action, 'userEmail', '').toLowerCase(),
      }
    case SET_PROFILE_INFO: 
      const profileState = {
        ...state,
        birthYear: action.birthYear,
        howMutchShouldInvestInFirstYear: action.howMutchShouldInvestInFirstYear,
        overallInvestedPortfolio: action.overallInvestedPortfolio,
        joinMailingList: action.joinMailingList,
        joinEarlyAccess: action.joinEarlyAccess
      }
      
      if (profileState.joinEarlyAccess && !profileState.joinedToMailChimp) {
        subscribeToMailList({
          fullname: profileState.userFullName,
          mail: profileState.userEmail,
          tags: profileState.joinMailingList ? ["Early Access - Beta", "Newsletter"] : ["Early Access - Beta"]
        })
        profileState.joinedToMailChimp = true
      }
      return profileState
    case SET_PROFILE_COMPLETE: 
      return {
        ...state,
        isUserOptIn: action.isUserOptIn
      }
    case SET_RESULTS:
      const resultState = {
        ...state,
        // Initial Deposit is gone, now it must be the same as Initial Investment
        initialDeposit: action.initialInvestment, 
        recurringAmount: action.recurringAmount,
        portfolioModel: action.portfolioModel,
        riskModel: action.riskModel,
        initialInvestment: action.initialInvestment,
        impactAreas: action.impactAreas
      };

      // console.log(resultState)
      pushAppState(resultState)

      return resultState

    case GO_BACK:
      const changedCompletedSteps = [...state.lastCompletedSteps].splice(
        0,
        state.lastCompletedSteps.length - 1
      );
      return {
        ...state,
        lastCompletedSteps: changedCompletedSteps.length
          ? changedCompletedSteps
          : [START],
      };
    case COMPLETE_STEP:
      return {
        ...state,
        lastCompletedSteps: checkCompletedSteps(state, action.payload as string),
      };
    default:
      return state;
  }
}
