import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"
import { AppStore } from '../storage/types'
import { PushAppState, SubscribeToMailList } from './FirebaseFunctions'

const config = {
  apiKey: "AIzaSyDUqeKOhIuSON_66bN_M8W-wEdCbfFoEvg",
  authDomain: "ri-beta.firebaseapp.com",
  databaseURL: "https://ri-beta.firebaseio.com",
  projectId: "ri-beta",
  storageBucket: "ri-beta.appspot.com",
  messagingSenderId: "734152580644",
  appId: "1:734152580644:web:6e543acdaf0a02f5e3c2b1",
  measurementId: "G-LBF9826LK0"
}

export const firebaseImpl = firebase.initializeApp(config);
export const firebaseFirestore = firebase.firestore();

export function authWithGoogle(): Promise<any> {
  firebase.auth().useDeviceLanguage();
  const authProvider = new firebase.auth.GoogleAuthProvider();
  
  authProvider.setCustomParameters({
    'login_hint': 'user@example.com'
  });

  return firebase.auth().signInWithPopup(authProvider).then(function(result: any) {
    const token = result.credential.accessToken;
    const user = result.user;

    return {token, user}
  }).catch(function(error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    
    const email = error.email;
    const credential = error.credential;
    
    return { errorCode, errorMessage, email, credential }
  });
}

export function subscribeToMailList(user: any) {
  const func = new SubscribeToMailList()
  func.call(user)
    .catch(err => console.log(err));
}

export function pushAppState (state: AppStore) {
  const func = new PushAppState()
  func.call(state)
    .catch(err => console.log(err));
}