export const InvestingReason_Retirement = 'RETIREMENT';
export const InvestingReason_Education = 'EDUCATION';
export const InvestingReason_Wealth = 'WEALTH';
export const InvestingReason_Downpayment = 'DOWNPAYMENT';
export const InvestingReason_Other = 'OTHER';

export const Conservative = 'Conservative';
export const ModeratelyConservative = 'Moderately Conservative';
export const ModeratelyAggressive = 'Moderately Aggressive';
export const Aggressive = 'Aggressive';
export const USEquityOnly = 'US Equity Only';

export const TaxAdvantagedConservative = 'ta_r1';
export const TaxAdvantagedModeratelyConservative = 'ta_r2';
export const TaxAdvantagedModeratelyAggressive = 'ta_r3';
export const TaxAdvantagedAggressive = 'ta_r4';
export const TaxableConservative = 'tx_r1';
export const TaxableModeratelyConservative = 'tx_r2';
export const TaxableModeratelyAggressive = 'tx_r3';
export const TaxableAggressive = 'tx_r4';
export const TaxableUSEquityOnly = 'useq';
