import React, { Component, Fragment } from 'react';
import loadable from '@loadable/component';
import { withRouter } from 'react-router';
import { Fade } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import './App.scss';

import { START } from './constants/routes';
import { AppStore } from './storage/types';
import { initIntercom } from './helpers/intercom';

const Loader = loadable(
  /* webpackPrefetch: true */ () => import('./components/Loader')
);
const PageNotFound = loadable(
  /* webpackPrefetch: true */ () => import('./container/PageNotFound')
);
const AppStepsContainer = loadable(
  /* webpackPrefetch: true */ () => import('./container/AppStepsContainer')
);

interface AppProps {
  lastCompletedStep: string;
}

interface AppState {
  isLoading: boolean;
  transitionCompleted: boolean;
}

class App extends Component<AppProps, AppState> {
  state: AppState = {
    isLoading: true,
    transitionCompleted: true,
  };

  componentDidMount() {
    this.setState({
      isLoading: false,
    });

    initIntercom();
  }

  render() {
    const { isLoading, transitionCompleted }: AppState = this.state;

    return (
      <div className="App">
        {isLoading ? (
          <Loader />
        ) : (
          <Fade in={transitionCompleted}>
            <div>
              <Fragment>
                <Switch>
                  <Route
                    path={START}
                    exact
                    render={() => <AppStepsContainer />}
                  />
                  <Route render={() => <PageNotFound />} />
                </Switch>
              </Fragment>
            </div>
          </Fade>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppStore) => ({
  lastCompletedSteps: state.lastCompletedSteps,
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(App));
