import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from './styles/theme';
import history from './helpers/history';
import { Router } from 'react-router-dom';
import { default as configureStore } from './storage';
import { Provider } from 'react-redux';
import storage from './libs/storage'

const APP_STORAGE = 'redux_review';

const store = configureStore(storage.get(APP_STORAGE))

store.subscribe(() => {
  if (!storage.get('debug') || (process.env.NODE_ENV !== 'production')) {
    storage.set(APP_STORAGE, store.getState());
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Router history={history}>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
